import React, { useMemo } from 'react';
import { Menu, MenuProps } from 'antd';
import { navigationPartner, navigationRepetitor, navigationStudent } from './navigation';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../redux/authSlice';
interface IHeaderProps{
  setCollapsed: (collapsed: boolean) => void;
  collapsed: boolean;
  isMobile: boolean;
}
const MenuSider: React.FC<IHeaderProps> = ({setCollapsed, isMobile}) => {
  const navigate = useNavigate();
  const user = useSelector(selectCurrentUser)
  const location = useLocation()
 
  
  const navigation = useMemo(() => {
    const pathname = location.pathname.split('/')[1]
    if(pathname === 'partner')
      return navigationPartner
    else if(user?.role === "repetitor")
      return navigationRepetitor
    else if(user?.role === "student")
      return navigationStudent
  }, [user?.role, location])
  const onClick: MenuProps['onClick'] = (e) => {
    navigate('/' + e.key)
    if(isMobile)
      setCollapsed(true)
  };
  return <Menu
    theme="light"
    mode="inline"
    onClick={onClick}
    defaultSelectedKeys={['1']}
    items={ navigation }
    style={ {height: "100%", zIndex: 100, width: '100%'} }
  />
};

export default MenuSider;