import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from '../../redux'
import { API_PATH, BASE_URL } from '../../constants'
import { DetailsAddRequest, DetailsEditRequest, DetailsResponse } from '../../types/detailsTypes'



export const apiDetails = createApi({
  reducerPath: 'apiDetails',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/${API_PATH}/`,
    
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = (getState() as RootState).auth.token
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  refetchOnMountOrArgChange: 10,
  endpoints: (builder) => ({
    detailsAdd: builder.mutation<DetailsResponse, DetailsAddRequest>({
      query: (credentials) => ({
        url: 'details/add',
        method: 'POST',
        body: credentials,
      }),
    }),
    detailsEdit: builder.mutation<DetailsResponse, DetailsEditRequest>({
      query: (credentials) => ({
        url: 'details/edit',
        method: 'PUT',
        body: credentials,
      }),
    }),
   
    getDetails: builder.query<DetailsResponse, string>({
      query: () => {
        return 'details'
      },
    }),
    
  }),
})

export const { useDetailsAddMutation, useDetailsEditMutation, useGetDetailsQuery } = apiDetails
