import { lazy } from 'react';

export const Homeworks = lazy(() => import('./Homeworks'));
export const HomeworkForm = lazy(() => import('./Homeworks/НomeworksRepetitor/HomeworkForm'));
export const Homework = lazy(() => import('./Homeworks/Homework'));
export const Registration = lazy(() => import('./Registration'));
export const Schedule = lazy(() => import('./Schedule'));
export const Finance = lazy(() => import('./Finance'));
export const Repetitors = lazy(() => import('./Repetitors'));
export const Students = lazy(() => import('./Students'));
export const Dashboard = lazy(() => import('./Dashboard'));
export const Profile = lazy(() => import('./Profile'));
export const Subscribe = lazy(() => import('./Subscribe'));
export const Login = lazy(() => import('./Login'));
export const Privacy = lazy(() => import('./Privacy'));
export const Details = lazy(() => import('./Details'));
export const Offer = lazy(() => import('./Offer'));
export const PartnerDetails = lazy(() => import('./Partner/Details'));
export const PartnerPromocodes = lazy(() => import('./Partner/Promocodes'));