import './App.css';
import { createBrowserRouter, LoaderFunctionArgs, redirect } from 'react-router-dom';
import Layout from './Layout';
import Cookies from "js-cookie";

import {
  Homeworks, 
  HomeworkForm, 
  Homework,
  Registration,
  Schedule,
  Students,
  Repetitors,
  Dashboard,
  Profile,
  Login,
  Finance,
  Subscribe, 
  Privacy,
  Details,
  Offer,
  PartnerDetails,
  PartnerPromocodes
} from '../pages';
import EmailActivationPage from '../pages/Registration/emailActivationPage';


function protectedLoader({ request }: LoaderFunctionArgs) {
  const accessToken = Cookies.get("accessToken")
  // If the user is not logged in and tries to access `/protected`, we redirect
  // them to `/login` with a `from` parameter that allows login to redirect back
  // to this page upon successful authentication
  if (!accessToken) {
    let params = new URLSearchParams();
    params.set("from", new URL(request.url).pathname);
    return redirect("/login?" + params.toString());
  }
  return null;
}

async function loginLoader() {
  const accessToken = Cookies.get("accessToken")
  if (accessToken) {
    return redirect("/schedule");
  }
  return null;
}
async function freeLoader() {
  return null;
}

export const router = createBrowserRouter([
  {
    id: "root",
    path: "/",
    loader() {
      // Our root route always provides the user, if logged in
      return { user: "" };
    },
    Component: Layout,
    children: [
      {
        path: "login",
        loader: loginLoader,
        Component: () => <Login />,
      },
      {
        path: "registration",
        loader: loginLoader,
        Component: () => <Registration />,
      },
      {
        path: "email-activation",
        loader: protectedLoader,
        Component: () => <EmailActivationPage />,
      },
      {
        index: true,
        path: "finance",
        loader: protectedLoader,
        Component: () => <Finance />,
      },
      {
        index: true,
        path: "schedule",
        loader: protectedLoader,
        Component: () => <Schedule />,
      },
      {
        index: true,
        path: "homeworks/:key",
        loader: protectedLoader,
        Component: () => <Homeworks />,
      },
      {
        index: true,
        path: "homework",
        loader: protectedLoader,
        Component: () => <HomeworkForm />,
      },
      {
        index: true,
        path: "homework/:id",
        loader: protectedLoader,
        Component: () => <Homework />,
      },
      {
        index: true,
        path: "homework/:id/edit",
        loader: protectedLoader,
        Component: () => <HomeworkForm />,
      },
      {
        path: "reports",
        loader: protectedLoader,
        Component: () => <Dashboard />,
      },
      {
        path: "students",
        loader: protectedLoader,
        Component: () => <Students />,
      },
      {
        path: "repetitors",
        loader: protectedLoader,
        Component: () => <Repetitors />,
      },
      {
        path: "profile",
        loader: protectedLoader,
        Component: () => <Profile />,
      },
      {
        index: true,
        path: "profile/:key",
        loader: protectedLoader,
        Component: () => <Profile />,
      },
      {
        path: "subscribe", 
        loader: protectedLoader,
        Component: () => <Subscribe />,
      },
      {
        path: "privacy", 
        loader: freeLoader,
        Component: () => <Privacy />,
      },
      {
        path: "details", 
        loader: freeLoader,
        Component: () => <Details />,
      },
      {
        path: "offer", 
        loader: freeLoader,
        Component: () => <Offer />,
      },
      {
        path: "partner/details", 
        loader: freeLoader,
        Component: () => <PartnerDetails />,
      },
      {
        path: "partner/promocodes", 
        loader: freeLoader,
        Component: () => <PartnerPromocodes />,
      },
      
    ],
  },
]);


