import React, { Suspense, useState } from 'react';
import {  Layout, Spin, theme } from 'antd';
import HeaderLayout from './Header';
import MenuSider from './menuSider';

const { Sider, Content } = Layout;

const LayoutFull: React.FC<{children: React.ReactElement, height: number}> = ({children, height}) => {

  const [collapsed, setCollapsed] = useState(true);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const siderStyle: React.CSSProperties = {
    overflow: 'auto',
    position: 'fixed',
    insetInlineStart: 0,
    zIndex: 100,
    top: 0,
    bottom: 0,
    scrollbarWidth: 'thin',
    scrollbarGutter: 'stable',
  };
 
  return (
    <Layout >
      <Sider width={210} theme={'light'} trigger={null} collapsible collapsed={collapsed} style={siderStyle}>
        <div className="demo-logo-vertical" />
        <MenuSider setCollapsed={setCollapsed} collapsed={collapsed} isMobile={true} />
      </Sider>
      <Layout style={{ marginInlineStart: collapsed ? 80 : 210 }}>
      <HeaderLayout setCollapsed={setCollapsed} collapsed={collapsed}  />

        <Content
          style={{
            margin: '24px 16px 24px 16px',
            padding: 24,
            height: height - 112,
            overflow: 'auto',
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <Suspense fallback={<Spin />}>
            {children}
          </Suspense>
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutFull;