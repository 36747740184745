import {
  UserOutlined,
  CalendarOutlined,
  BarChartOutlined,
  ReadOutlined,
  WalletOutlined,
  TeamOutlined,
  TagOutlined,
  SettingOutlined,
} from '@ant-design/icons';


export const navigationRepetitor = [
  {
    key: 'reports',
    icon: <BarChartOutlined />,
    label: 'Отчеты',
  },
  {
    key: 'finance',
    icon: <WalletOutlined />,
    label: 'Финансы',
  },
  {
    key: 'schedule',
    icon: <CalendarOutlined />,
    label: 'Расписание',
  },
  {
    key: 'students',
    icon: <TeamOutlined />,
    label: 'Ученики',
  },
  {
    key: 'homeworks/toCheck',
    icon: <ReadOutlined />,
    label: 'Домашние задания',
  },
]
export const navigationPartner= [

  {
    key: 'partner/details',
    icon: <SettingOutlined />,
    label: 'Реквизиты',
  },
  {
    key: 'partner/promocodes',
    icon: <TagOutlined />,
    label: 'Промокоды',
  },
]
export const navigationStudent = [
  {
    key: 'reports',
    icon: <BarChartOutlined />,
    label: 'Отчеты',
  },
  {
    key: 'finance',
    icon: <WalletOutlined />,
    label: 'Финансы',
  },
  {
    key: 'schedule',
    icon: <CalendarOutlined />,
    label: 'Расписание',
  },
  {
    key: 'repetitors',
    icon: <UserOutlined />,
    label: 'Репетиторы',
  },
  {
    key: 'homeworks/active',
    icon: <ReadOutlined />,
    label: 'Домашние задания',
  },
]