import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '.'
import Cookies from 'js-cookie'
import { Group, Student, StudentsForTable, StudentsResponse } from '../types/studentsTypes'
import { Details, DetailsResponse } from '../types/detailsTypes'

type DetailsState = {
  details: Details | null,

}

const slice = createSlice({
  name: 'details',
  initialState: { details: null } as DetailsState,
  reducers: {
    setDetails: (
      state,
      {
        payload: { details },
      }: PayloadAction<DetailsResponse>
    ) => {
      state.details = details
    },
   
  },
})

export const { setDetails } = slice.actions

export default slice.reducer

export const selectDetails = (state: RootState) => state.details.details

